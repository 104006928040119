import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "components/common/seo";
import Top from "./components/top";
import FaqContent from "./components/content";
import { FaqContainer } from "./styles";

interface FaqPageProps {}

const Faq: React.FC<FaqPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      faqData: allPrismicFaqPage {
        nodes {
          data {
            title {
              richText
            }
            top_center_image {
              gatsbyImageData
            }
            secondary_title {
              richText
            }
            top_tile_image {
              gatsbyImageData
            }
            body {
              ... on PrismicFaqPageDataBodyFaqsContent {
                id
                primary {
                  main_content_title {
                    richText
                  }
                }
                items {
                  section_content_title {
                    richText
                  }
                  section_paragraph {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.faqData.nodes[0].data;
  return (
    <>
      <Seo />

      <FaqContainer className={"faq-container"}>
        <Top document={document} />
        <FaqContent content={document.body} />
      </FaqContainer>
    </>
  );
};

export default Faq;
