import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const FaqContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.faq-container {
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.primary.light};
  }
`;
