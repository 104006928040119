import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const ContentContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.faq-content-container {
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    padding: ${({ theme }) => theme.spacing(8, 10, 40)};

    & > .inner-content-container {
      display: flex;
      gap: ${({ theme }) => `max(6vw, ${theme.spacing(15.5)})`};
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme }) => theme.spacing(2, 2, 8)};

      & > .inner-content-container {
        flex-direction: column;
        gap: ${({ theme }) => `max(6vw, ${theme.spacing(1)})`};

        & > .subitem-accordion-list-container {
          display: none;
        }
      }

      & .cover-button-container {
        display: block;
      }
    }
  }
`;

export const TopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.top-container {
    & > .top-banner-container {
      height: 100vh;
      display: flex;
      position: relative;

      overflow: hidden;
      & > .faq-page-images {
        height: min(100vh, 745px);
        position: relative;
        display: flex;
        align-self: flex-end;
        width: 100%;
        justify-content: center;
        & > .center-image {
          position: absolute;
          max-width: 1440px;
          bottom: 0;
          z-index: 1;
        }
        & > .tile-image-container {
          min-width: 100vw;
          position: absolute;
          bottom: 0;
          background-color: #caf0f4;
          max-height: 200px;
        }
      }
      & > .top-title-container {
        color: ${({ theme }) => theme.palette.primary.main};
        font-family: "utopia-std-display";
        text-align: center;
        position: absolute;
        top: min(25%, 196px);
        left: 50%;
        transform: translate(-50%, 0);

        & > h1 {
          font-weight: 400;
          font-size: 80px;
          line-height: 96px;
        }
      }
    }

    & > .secondary-title-container {
      color: ${({ theme }) => theme.palette.primary.main};
      text-align: center;
      padding: ${({ theme }) => theme.spacing(0, 2, 2)};

      bottom: 0px;
      z-index: 50;
      & > h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 27px;
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & > .top-banner-container {
        & > .top-title-container {
          & > h1 {
            font-size: 52px;
          }
        }
        & .faq-page-images {
          & > .center-image {
            width: 180%;
            bottom: -25px;
          }
        }
      }
    }
  }
`;
