import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const SubjectListContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.subject-list-container {
    min-width: 36%;
    color: ${({ theme }) => theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1.5)};

    & .mobile-subject-items-container {
      display: none;
      padding: ${({ theme }) => theme.spacing(1.25, 0)};
    }

    & > .subject-list {
      & > .subject-container {
        border-radius: 4px;
        padding: ${({ theme }) => theme.spacing(3, 3.5)};
        font-family: "utopia-std-display";
        outline: 0 solid white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: outline-color 0.2s ease-in;
        background-color: ${({ theme: { palette } }: ThemeType) =>
          palette.p_white.full};
        &.active,
        &:hover {
          outline: 1px solid #4726d1;
          cursor: pointer;
          & > .title-arrow-icon {
            opacity: 1;
          }
        }
        & > .title-arrow-icon {
          width: 20px;
          font-weight: bold;
          opacity: 0;
          transition: opacity 0.1s ease-in;
        }
        & > .subject-title-container {
          & > h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 1.372;
          }
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & > .subject-list {
        & > .subject-container {
          padding: ${({ theme }) => theme.spacing(3.5, 2)};
          & > .title-arrow-icon {
            opacity: 1;
            transform: rotate(90deg);
          }
          &:hover {
            outline: none;
          }

          &.active {
            outline: 1px solid #4726d1;
            cursor: pointer;
          }

          & > .subject-title-container {
            & > h3 {
              font-size: 18px;
            }
          }
        }
        & > .mobile-subject-items-container {
          display: block;
          & > .subitem-accordion-list-container {
            & > .sub-item-accordion {
              padding: ${({ theme }) => theme.spacing(2)};
            }
          }
        }
      }
    }
  }
`;
