import * as React from "react";
import Box from "@mui/material/Box";
import { GatsbyImage } from "gatsby-plugin-image";
import { TopContainer } from "../styles";
import { TopProps } from "../types";
import { PrismicRichText } from "@prismicio/react";

const Top: React.FC<TopProps> = ({ document }) => {
  return (
    <TopContainer className={"top-container"}>
      <Box className={"top-banner-container"}>
        <Box className={"top-title-container"}>
          <PrismicRichText field={document.title.richText} />
        </Box>
        <Box className={"faq-page-images"}>
          <GatsbyImage
            className={"center-image"}
            alt={"center"}
            image={document.top_center_image.gatsbyImageData}
          />
          <Box className="tile-image-container">
            <GatsbyImage
              className={"tile-image"}
              alt={"tiles"}
              image={document.top_tile_image.gatsbyImageData}
            />
          </Box>
        </Box>
      </Box>
      <Box className={"secondary-title-container"}>
        <PrismicRichText field={document.secondary_title.richText} />
      </Box>
    </TopContainer>
  );
};

export default Top;
