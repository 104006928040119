import React from "react";
import Faq from "../components/pages/faq";

interface FaqProps {}

const FaqPage: React.FC<FaqProps> = () => {
  return <Faq />;
};

export default FaqPage;
