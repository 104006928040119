import React from "react";
import { Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SubjectListContainer } from "./styles";
import { SubjectListProps, SubjectProps } from "./types";
import SubitemList from "../sub-item-list";
import { PrismicRichText } from "@prismicio/react";

const Subject: React.FC<SubjectProps> = ({
  title,
  setCurrentFaqIndex,
  index,
  currentFaqIndex,
}) => {
  const handleOpen = () => {
    if (index === currentFaqIndex) setCurrentFaqIndex(null);
    else {
      setCurrentFaqIndex(index);
    }
  };
  return (
    <Box
      onClick={handleOpen}
      className={`subject-container ${
        currentFaqIndex === index ? "active" : ""
      }`}
    >
      <Box className={"subject-title-container"}>
        <PrismicRichText field={title} />
      </Box>
      <ArrowForwardIosIcon className={`title-arrow-icon`} />
    </Box>
  );
};

const SubjectList: React.FC<SubjectListProps> = ({
  content,
  currentFaqIndex,
  setCurrentFaqIndex,
}) => {
  return (
    <SubjectListContainer className={"subject-list-container"}>
      {content.map((item, index) => {
        return (
          <Box className="subject-list" key={index}>
            <Subject
              title={item.primary.main_content_title.richText}
              key={item.id}
              index={index}
              setCurrentFaqIndex={setCurrentFaqIndex}
              currentFaqIndex={currentFaqIndex}
            />
            {currentFaqIndex === index && (
              <Box className="mobile-subject-items-container">
                <SubitemList
                  content={content}
                  currentFaqIndex={currentFaqIndex}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </SubjectListContainer>
  );
};

export default SubjectList;
