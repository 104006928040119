import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const SubitemListContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box {...props} />
)`
  &.subitem-accordion-list-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1.5)};
    & > .Mui-expanded {
      margin: 0;
    }

    & > .sub-item-accordion {
      border-radius: 4px;
      color: #787878;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      padding: ${({ theme }) => theme.spacing(2.75, 3.75)};
      margin-bottom: 0;
      border: 1px solid #f0ecea;
      transition: all 0.5s;

      &:before {
        background-color: transparent;
        & > .MuiButtonBase-root-MuiAccordionSummary-root {
          min-height: 0;
        }
        & > .sub-item-accordion-title {
          min-height: 0;
        }
      }

      &:hover {
        cursor: pointer;
      }
      .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.primary.main};
      }
      & > .MuiCollapse-root {
        & > .MuiCollapse-wrapper {
          & > .MuiCollapse-wrapperInner {
            & > .MuiAccordion-region {
              & > .sub-item-accordion-paragraph {
                padding: 0;
                padding-top: ${({ theme }) => theme.spacing(3)};
                & > p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      &.active,
      &:hover {
        border: 1px solid #4726d1;
        & > .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
          min-height: 0;
        }
        & > .sub-item-accordion-title {
          color: ${({ theme }) => theme.palette.primary.main};
          min-height: 0;
        }
      }

      & > .sub-item-accordion-title {
        padding: 0;
        min-height: 0;
        gap: 30px;
        color: #000;

        & > .MuiAccordionSummary-content {
          margin: 0;
          & > h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: auto;

      &
        .sub-item-accordion
        > .sub-item-accordion-title
        > .MuiAccordionSummary-content
        > h4 {
        font-size: 14px;
      }
    }
  }
`;
