import React, { useState } from "react";
import Box from "@mui/material/Box";
import FaqSubjectList from "../subject-list";
import FaqSubitemList from "../sub-item-list";
import { ContentContainer } from "../styles";
import { FaqContentProps } from "../types";

const FaqContent: React.FC<FaqContentProps> = ({ content }) => {
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 720;
  const [currentFaqIndex, setCurrentFaqIndex] = useState<number | null>(
    isMobile ? null : 0
  );

  return (
    <ContentContainer className={"faq-content-container"}>
      <Box className={"inner-content-container"}>
        <FaqSubjectList
          currentFaqIndex={currentFaqIndex}
          setCurrentFaqIndex={setCurrentFaqIndex}
          content={content}
        />
        <FaqSubitemList content={content} currentFaqIndex={currentFaqIndex} />
      </Box>
    </ContentContainer>
  );
};

export default FaqContent;
