import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { SubitemListContainer } from "./styles";
import { SubitemListProps, SubitemProps } from "./types";
import { PrismicRichText } from "@prismicio/react";

const SubItem: React.FC<SubitemProps> = ({
  title,
  paragraph,
  currentFaqIndex,
}) => {
  const [itemIsOpen, setItemIsOpen] = useState(false);

  useEffect(() => {
    setItemIsOpen(false);
  }, [currentFaqIndex]);

  return (
    <Accordion
      expanded={itemIsOpen}
      onClick={() => setItemIsOpen(!itemIsOpen)}
      className={`sub-item-accordion ${itemIsOpen ? "active" : ""}`}
    >
      {title && title.length > 0 && (
        <AccordionSummary
          expandIcon={itemIsOpen ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
          aria-controls="panel1a-content"
          className={"sub-item-accordion-title"}
        >
          <PrismicRichText field={title} />
        </AccordionSummary>
      )}
      {paragraph && paragraph.length > 0 && (
        <AccordionDetails className={"sub-item-accordion-paragraph"}>
          <PrismicRichText field={paragraph} />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const SubitemList: React.FC<SubitemListProps> = ({
  content,
  currentFaqIndex,
}) => {
  return (
    <SubitemListContainer className={"subitem-accordion-list-container"}>
      {currentFaqIndex !== null &&
        content[currentFaqIndex].items.map((item, index) => {
          return (
            <SubItem
              title={item.section_content_title?.richText}
              paragraph={item.section_paragraph?.richText}
              currentFaqIndex={currentFaqIndex}
              key={index}
            />
          );
        })}
    </SubitemListContainer>
  );
};

export default SubitemList;
